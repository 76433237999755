'use client'
// Internal modules
import { useCookie } from '@/utils/Session'

// Next modules
import Link from 'next/link'
import Image from 'next/image'
import { useRouter, useSearchParams } from 'next/navigation'

// React modules
import { useEffect, useState } from 'react'

// Components
import { ISessionContext, useSessionContext } from '@/app/_components/context'
import { Button, Input } from '@/app/_components/html'

const Login = () => {  
  const [loggedInCookie, setLoggedInCookie] = useCookie('loggedIn')
  const [, setSessionCookie] = useCookie('sessionId')
  const [ , setSessionEmail ] = useSessionContext() as ISessionContext

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [hasAuthError, setHasAuthError] = useState('')
  const [redirectTarget, setRedirectTarget] = useState('/reports/')

  const router = useRouter()
  const query = useSearchParams()

  // Handle the redirect parameter, and also automatically 
  // redirect if already logged in
  useEffect(() => {
    if (query?.get('redirect')) {
      const r = (query.get('redirect') as string).replace(/[^a-z-]/, '') // scrub anything that isn't a-z or -
      setRedirectTarget(`/${r}/`)
    }

    if (loggedInCookie()) {
      console.info('Already logged in, redirecting to ' + redirectTarget)
      router.push(redirectTarget)
    }
  }, [query])

  // Handle the "login" button click, send to
  // the redirect target (default: connected-apps) on
  // success, or show an error on failure
  const handleLogin = async () => {
    if (!email || !password) {
      setHasAuthError('Please enter an email and password')
      return
    }

    const resp = await fetch('/api/session', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    const user = await resp.json();

    if (user.session) {
      setLoggedInCookie(true)
      setSessionEmail(email)
      setSessionCookie(user.session)

      console.info(`Logged in ${user.email}, redirecting to ${redirectTarget}`)
      return router.push(redirectTarget)
    } else {
      setHasAuthError('Invalid email or password');
    }

    return false
  };

  return (
    <>
    <div className="flex md:hidden flex-row my-2 items-center justify-center">
      <Image 
        src={`${process.env.NEXT_PUBLIC_BASE_URL}/assets/images/dessertdata/cake1.png`} 
        alt="cake logo" 
        width={40}
        height={40}
        className=""
      />
      <p className="text-xl font-bold mx-2">Dessert Data</p>
      <p className="text-ld bg-theme-brand-50 text-theme-brand-600 px-2 rounded-lg">Beta</p>
    </div>
    <main className='flex md:hidden flex-col text-center mt-20'>
      <h1 className='text-lg font-bold'>Welcome</h1>
      <p className='text-theme-gray-600'>Sign in to continue</p>
      <form className='mx-5 mt-8'>
        <Input 
          id='login-email'
          type='email' 
          value={email}
          placeholder='Email address' 
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input 
          id='login-pw'
          type='password' 
          value={password}
          placeholder='Password'
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button 
          type="button"
          onClick={handleLogin}
        >
          Sign In
        </Button>
        {hasAuthError && <p className='text-theme-error-600 mt-3'>{hasAuthError}</p>}
      </form>
      <div className='mx-5 mt-5 mb-5'>
        <Link href='/signup/' className=''>Sign Up</Link>
        {` / `}
        <Link href='/forgot-password/'>Forgot Password</Link>
      </div>
        <nav className='fixed bottom-2 left-0 w-full'>
          <ul className="flex flex-wrap justify-center text-sm mt-8 mb-2">
            <li className="mr-6">
              <Link href="/privacy-policy/" className="text-theme-gray-700 border-none hover:text-theme-gray-900">
                  Privacy Policy
              </Link>
            </li>
            <li className="mr-6">
              <Link href="/terms-and-conditions/" className="text-theme-gray-700 border-none hover:text-theme-gray-900">
                  Terms And Conditions
              </Link>
            </li>
          </ul>
        </nav>
    </main>
    <main className='hidden md:flex flex-col text-center bg-theme-brand-600 h-screen'>
      <div className='w-1/2 m-auto bg-white rounded-3xl shadow-lg shadow-theme-gray-900 max-w-xl'>
        <div className="flex flex-row my-2 justify-center items-center">
          <Image 
            src={`${process.env.NEXT_PUBLIC_BASE_URL}/assets/images/dessertdata/cake1.png`} 
            alt="cake logo" 
            width={60}
            height={60}
            className=""
          />
          <p className="text-md bg-theme-brand-50 text-theme-brand-600 px-2 rounded-lg">Beta</p>
        </div>
        <h1 className='text-xl font-bold mt-8 text-theme-brand-900'>Welcome</h1>
        <p>Sign in to continue</p>
        <form className='mx-5 mt-10'>
          <Input 
            id='login-email'
            type='email' 
            value={email}
            placeholder='Email address' 
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input 
            id='login-pw'
            type='password' 
            value={password}
            placeholder='Password'
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button 
            type="button"
            onClick={handleLogin}
          >
            Sign In
          </Button>
          {hasAuthError && <p className='text-theme-error-600 mt-3'>{hasAuthError}</p>}
        </form>
        <div className='mx-5 mt-5 mb-5'>
          <Link href='/signup/' className=''>Sign Up</Link>
          {` / `}
          <Link href='/forgot-password/'>Forgot Password</Link>
        </div>
        <nav>
          <ul className="flex flex-wrap justify-center text-sm mt-8 mb-2">
            <li className="mr-6">
              <Link href="/privacy-policy/" className="text-theme-gray-700 border-none hover:text-theme-gray-900">
                  Privacy Policy
              </Link>
            </li>
            <li className="mr-6">
              <Link href="/terms-and-conditions/" className="text-theme-gray-700 border-none hover:text-theme-gray-900">
                  Terms And Conditions
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </main>
    </>
  )
};

export default Login
